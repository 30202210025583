import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import PersonList from './person_list.vue';

formCreate.component('PersonList', PersonList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      progressList: [],
      chooseAct: [],
      formFunctionCode: 'change_person_form',
      buttonText: {
        submit: '保存',
      },
    };
  },

  created() {

  },

  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'newFullName') { // 选择变更负责人
        v.props = {
          ...v.props,
          params: {
            functionCode: 'person_list_1',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
        v.on = {
          ...v.on,
          change: (e) => {
            console.log(e);
            this.setValue({
              newUserName: e[0].userName,
              newFullName: e[0].fullName,
              newPositionCode: e[0].positionCode,
              newPositionName: e[0].positionName,
              newOrgCode: e[0].orgCode,
              newOrgName: e[0].orgName,
            });
          },
        };
      }
      return v;
    },

    formComplete() {
      this.init();
      this.disabled(true, ['ext40', 'ext39', 'ext69', 'ext70', 'ext64', 'ext74', 'newUserName', 'newPositionCode', 'newPositionName', 'newOrgCode', 'newOrgName']);
    },

    // 提交
    async submit() {
      const params = this.getFormData();
      if (!params) return false;
      params.actDetailCode = this.formConfig.row.actDetailCode;
      params.actCode = this.formConfig.row.actCode;
      params.ext69 = params.newPositionCode;
      params.ext70 = params.newPositionName;
      params.ext64 = params.newOrgCode;
      params.ext74 = params.newOrgName;
      params.ext40 = params.newFullName;
      params.ext39 = params.newUserName;
      const url = '/tpm/tpmActExt/updatePrincipal';
      const res = await request.post(url, params);
      if (res.success) {
        this.$message({
          type: 'success',
          message: res.message,
        });
        this.$emit('onClose');
        this.$emit('onGetList');
      }
    },

    // 初始化数据
    async init() {
      const { row } = this.formConfig;
      const data = await request.get('/tpm/tpmActExt/getPrincipal', { actDetailCode: row.actDetailCode }).then((res) => (res.success ? res.result : []));
      this.setValue(data);
    },
  },
};
